import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Navbar from "./Navbar";
import { GET_CHALLENGE } from "../constant/constant";
import { BASE_URL, GET_USER } from "../constant/constant";

const Challanges = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const getSessionItemWithExpiry = (key) => {
          const itemStr = sessionStorage.getItem(key);
          if (!itemStr) return null;

          const item = JSON.parse(itemStr);
          const now = new Date();
          if (now.getTime() > item.expiry) {
            sessionStorage.removeItem(key);
            return null;
          }

          return item.value;
        };

        const userId = getSessionItemWithExpiry("userId");
        if (!userId) {
          throw new Error("User  ID not found in session.");
        }
        const userResponse = await axios.get(`${GET_USER}/${userId}`);
        const user = userResponse.data.user;

        const requiredFields = [
          "name",
          "fatherName",
          "motherName",
          "address",
          "state",
          "city",
          "pincode",
          "educationQualification",
          "email",
          "phone",
          "gender",
        ];

        const isProfileComplete = requiredFields.every(
          (field) => user[field] && user[field].toString().trim() !== ""
        );

        if (!isProfileComplete) {
          Swal.fire({
            title: "Incomplete Profile",
            text: "Please complete your profile before proceeding.",
            icon: "warning",
            confirmButtonText: "Go to Profile",
          }).then(() => {
            navigate("/profile");
          });
          return;
        }

        const chaptersResponse = await axios.get(`${GET_CHALLENGE}/${userId}`);
        const chaptersWithStatus = chaptersResponse.data.data.map(
          (chapter) => ({
            ...chapter,
            attempted: chapter.attempted || false,
          })
        );
        setChapters(chaptersWithStatus);
        setLoading(false);
      } catch (err) {
        console.error(err.message);
        setError("Failed to fetch chapters or user data");
        setLoading(false);
      }
    };

    fetchUserData();
  }, [navigate]);

  const { chapter: currentChapter, Submitted } = location.state || {};

  useEffect(() => {
    if (!loading && chapters.length > 0) {
      const allSubmitted = chapters.every((chapter) => chapter.attempted);
      if (allSubmitted) {
        Swal.fire({
          title: "Quiz Completed!",
          text: "You have successfully completed the quiz.",
          icon: "success",
          confirmButtonText: "Go to Certificates",
          customClass: {
            popup: 'custom-popup',
            title: 'custom-title',
            content: 'custom-content',
            confirmButton: 'custom-button',
          },
          didOpen: () => {
            // Add custom CSS directly here
            const style = document.createElement('style');
            style.innerHTML = `
              .custom-popup {
                background-color: #f0f8ff !important;
                border-radius: 15px;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
              }
              .custom-title {
                color: #4CAF50;
                font-family: 'Arial', sans-serif;
              }
              .custom-content {
                font-size: 16px;
                color: #333;
              }
              .custom-button {
                background-color: #4CAF50;
                color: white;
                border-radius: 5px;
                padding: 10px 20px;
                font-size: 16px;
              }
              .custom-button:hover {
                background-color: #45a049;
              }
            `;
            document.head.appendChild(style);
          }
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/certificates");
          }
        });
      }
    }
  }, [chapters, loading, navigate]);

  const handleAttemptClick = (chapter) => {
    navigate(`/challenges/${chapter}`);
  };

  const handleTryAgain = (chapterName) => {
    setChapters((prevChapters) =>
      prevChapters.map((chapter) =>
        chapter.chapter === chapterName
          ? { ...chapter, attempted: false }
          : chapter
      )
    );
    handleAttemptClick(chapterName);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <div className="bg-[#1D57A5] h-64 text-white">
        <Navbar />
        <h1 className="font-semibold text-2xl md:text-3xl mt-3 text-center text-white">
          BEGINNERS COURSE
        </h1>
        <h1 className="font-medium text-base md:text-xl text-center mt-3 px-4 md:px-0 text-white">
          Once you start any one of the Challenges, you have to complete the
          rest of the challenges within 15 days.
        </h1>
      </div>
      <div className="pt-8 pb-10 px-6 sm:px-10 md:px-20 lg:px-28 bg-gray-100">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
          {chapters.map((chapter, index) => (
            <div
              key={index}
              className="bg-[#1D57A5] rounded-lg p-4 sm:p-6 shadow-lg flex flex-col items-center"
            >
              <div className="w-12 h-12 md:w-16 md:h-16 bg-white text-blue-700 rounded-full flex items-center justify-center text-lg md:text-3xl font-bold mb-4">
                {index + 1}
              </div>

              <h3 className="text-white text-base md:text-lg font-semibold text-center mb-2">
                {chapter.chapter}
              </h3>

              <div className="border-t border-dotted border-white w-full my-2"></div>

              <p className="text-white text-center text-sm mt-2">
                Total Questions = 10
              </p>

              <div className="border-t border-dotted border-white w-full my-2"></div>

              <p className="text-white text-center text-sm font-semibold">
                {chapter.totalScore === false
                  ? "Unlimited Attempts Available"
                  : `Score: ${chapter.totalScore}%`}
              </p>

              <button
                onClick={() => {
                  if (Submitted && chapter.chapter === currentChapter) {
                    handleTryAgain(chapter.chapter);
                  } else {
                    handleAttemptClick(chapter.chapter);
                  }
                }}
                className={`mt-4 w-full py-2 rounded-lg text-sm font-semibold transition duration-300 ${
                  chapter.attempted
                    ? "bg-gray-500 text-white cursor-not-allowed"
                    : Submitted && chapter.chapter === currentChapter
                    ? "bg-orange-500 text-white hover:bg-orange-600"
                    : "bg-blue-500 text-white hover:bg-blue-600"
                }`}
                disabled={
                  chapter.attempted &&
                  !(Submitted && chapter.chapter === currentChapter)
                }
              >
                {Submitted && chapter.chapter === currentChapter
                  ? "Try Again"
                  : chapter.attempted
                  ? "Submitted"
                  : "Attempt"}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Challanges;
